<template>
  <div id="dgintelzhgd">
    <div class="cm-container">
      <div style="margin:20px 0px 20px 0px">
        <img style="width:100%;" src="@/assets/img/banner/banner1.png"
             alt="">
      </div>
      <!-- <breadcrumb></breadcrumb> -->
      <div class="line-c">
        <!-- <div class="ll-c">
          <img src="@/assets/img/cs/cs01.png" alt="" />
        </div> -->
        <div class="lr-c">
          <div class="lr-title">智慧设计协同体验平台</div>

          <div class="lrnl">

            PKPM设计院综合项目管理平台是将设计院的业务全过程信息化、业务流程规范化、标准化；通过信息化的管理手段，提升设计产品质量，同时提高员工的工作效率，降低设计院的运营管理成本，从而逐步提升设计院的整体管理水平和设计院的行业地位。其功能设置包含客户管理、市场管理、合同管理、项目任务管理、设计管理、进度管理、质量控制、成本费用、工作量统计、绩效管理、人员管理、日常办公、图档管理等
          </div>
        </div>
        <div class="ll-c">
          <img src="@/assets/img/common/数字设计协同体验平台-封面.png"
               alt="" />
        </div>
      </div>

      <div class="cm-img">
        <div class="cm-img-list"
             v-for="(item, index) in ddList"
             :key="index">

          <div>{{ item.text }}</div>
          <div>{{item.a01}}</div>
          <div>
            <span @click="zhgdfn(item)">去查看</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let Base64 = require("js-base64").Base64;
import { encryptDes } from "@/js/des";
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setStorage } from "@/js/common";
// import breadcrumb from "@/components/breadcrumb";
// import router from "../../router/index.js";
export default {
  setup () {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      major: [
        { name: "全部", id: 1 },
        { name: "建筑工程", id: 2 },
        { name: "道桥工程", id: 3 },
        { name: "地下工程", id: 4 },
      ],
      majoractive: 1,
      jobs: [
        { name: "全部", id: 1 },
        { name: "施工员（测量员）", id: 2 },
        { name: "质量员", id: 3 },
        { name: "安全员", id: 4 },
        { name: "标准员", id: 5 },
        { name: "材料员", id: 6 },
        { name: "劳务员（预算员）", id: 7 },
        { name: "资料员", id: 8 },
      ],
      jobsactive: 1,
      ddList: [
        {
          poster: require("@/assets/img/cs/cs07.png"),
          id: 1,
          text: "主页",
          a01: "主页显示当前公司的当年签订总额、回款总额、中标金额等信息，点击左侧菜单，可查看客户管理、市场管理、分包商管理、合同管理等信息",
          a02: 'http://sxgd.hiaipc.com/#/home/index'

        }

      ],
    });
    const setbclist = (arr) => {
      store.commit("Setbclist", arr);
      setStorage("bclist", arr);
    };
    const methods = {
      toDetail: (item, datas) => {
        console.log(item);
        if (datas == 1) {
          let arr = [
            {
              text: "智慧设计",
              nindex: "3",
              path: "/home/digitalDesign",
              back: false,
            },
            {
              text: "课程介绍",
              nindex: "",
              path: "",
              back: false,
            },
          ];
          setbclist(arr);
          store.commit("SetnarbarMenuActive", "3s");
          setStorage("narbarMenuActive", "3s");
          router.push("/home/digitalDesignDetail");
        }
      },
      // 跳转
      zhgdfn: () => {
        // window.open(item.a02);
        // 钢筋集中加工和配送----绿色低碳科创云平台
        // let baseUrl = 'http://47.95.149.159/gjjgpsweb'
        // let uname = "SuperAdmin";
        // let id = "890c4192-0b18-4ce6-ae41-5f258d8ef887";

        // 设计院管理系统
        let baseUrl = 'http://47.95.149.159/sjyweb'
        let uname = "SuperAdmin";
        let id = "CEA52225-D79E-42B1-A006-474629638C99";
        let Base64desuname = encryptDes(uname, 'dfe5921b');
        let Base64desid = encryptDes(id, 'dfe5921b');
        let Base64url = Base64.encode(baseUrl + "/main");
        let enduname = escape(Base64desuname);
        let enddesid = escape(Base64desid);
        let enddesurl = escape(Base64url);
        let goURL = baseUrl + "/systemviews/signin/pkpmsignin.html?un=" + enduname + "&ei=" + enddesid + "&redirecturl=" + enddesurl;
        console.log(goURL)
        window.open(goURL);
      },
      back: () => {
        store.commit("SetnarbarMenuActive", "2");
        setStorage("narbarMenuActive", "2");
        router.push("/home/courseManagement");
      },
    };
    onMounted(() => { });
    onUnmounted(() => { });
    // provide('popfalse',state.popfalse)
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  // components: { breadcrumb },
};
</script>
<style lang="scss" scoped>
#dgintelzhgd {
  height: 100%;
  position: relative;

  .cm-container {
    width: 1200px;
    margin: 0 auto;
    background: #f3f3f3;
    padding: 0 0 100px;
    margin-top: 20px;
    .line-c {
      background: #fff;
      padding: 20px;
      display: flex;
      .ll-c {
        width: 290px;
        height: 192px;
        img {
          width: 290px;
          height: 100%;
          object-fit: contain;
        }
      }
      .lr-c {
        padding: 0 0 0 20px;
        flex-grow: 1;
        .lr-title {
          line-height: 24px;
          margin: 0 0 2px;
          font-size: 25px;
          font-family: PangMenZhengDao;
          color: #333333;
        }
        .lrnl {
          height: 19px;
          font-size: 14px;
          color: #666666;
          line-height: 19px;
          padding: 10px 0;
          width: 54%;
          // border-bottom:1px solid #EEEEEE;
          .spline {
            display: inline-block;
            width: 1px;
            height: 9.5px;
            margin: 0 20px;
            background: #eee;
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
    .nt {
      height: 20px;
      font-size: 15px;
      font-weight: bold;
      color: #555555;
      line-height: 20px;
      margin: 20px 0 14px;
    }
    .ntc {
      font-size: 14px;
      color: #555555;
      line-height: 19px;
      background: #fff;
      padding: 20px;
      .tl-c {
        padding: 0 0 0 15px;
        &.c01 {
          margin: 0 0 20px;
        }
        .tlol {
          position: relative;
          &::before {
            content: "";
            width: 4px;
            height: 4px;
            background: #333333;
            border-radius: 50%;
            position: absolute;
            left: -10px;
            top: 9px;
          }
        }
      }
    }
    .cm-img {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0 -20px;
      .cm-img-list {
        width: 100%;
        height: 92px;
        background: #fff;

        margin-bottom: 20px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: 78%;
          display: inline-block;
        }
        div {
          &:nth-child(1) {
            width: 8%;
            height: 52px;
            background: rgba(17, 161, 247, 0.27);
            text-align: center;
            line-height: 52px;
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
            margin:0px 10px 0px 10px;
          }
          &:nth-child(2) {
            width: 82%;
            padding: 0 6px;
            box-sizing: border-box;
          }
          &:nth-child(3) {
            width: 8%;
            span {
              width: 82px;
              height: 40px;
              background: #11a1f7;
              color: #fff;
              display: inline-block;
              margin-right: 10px;
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
        }
        &:nth-child(3n) {
        }
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  #dgintelzhgd {
    .cm-container {
      width: 1400px;
      .cm-img {
      }
      .line-c {
        background: #fff;
        padding: 20px;
        display: flex;
        .ll-c {
          width: 338.33px;
          height: 224px;
        }
      }
    }
  }
}
</style>
